import { Photo } from '@capacitor/camera'
import { Position } from '@capacitor/geolocation'

import { User } from '@app-graphql'

export type PhotoExifData = {
    photo: Photo | null
    position: Position | null
    user: Partial<User> | null
    uniqueId?: string
}
