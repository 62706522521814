import { Directive, AfterViewInit } from '@angular/core'
import { SwiperComponent } from 'swiper/angular'

/**
 * This directive fixes the issue where the 'initialSlide' is not applied correctly when using 'slidesPerView="auto"'.
 *
 * For some reason, the `activeIndex` starts at 1 and then moves to 2, 3, etc., depending on the number of slides.
 * This directive resets the swiper to the correct slide after the view has been initialized.
 */

@Directive({
    selector: '[appSlidesPerViewAutoFix]',
})
export class SlidesPerViewAutoFixDirective implements AfterViewInit {

    constructor(
        private readonly host: SwiperComponent,
    ) {
    }

    public ngAfterViewInit(): void {
        this.host?.swiperRef?.slideReset()
    }

}
