import { Directive, ElementRef, HostListener, Input } from '@angular/core'

@Directive({
    selector: '[appScrollToElement]',
})
export class ScrollToElementDirective {

    @Input('appScrollToElement')
    public element: ElementRef | HTMLElement | null = null

    @Input('appScrollToElementOptions')
    private options: boolean | ScrollIntoViewOptions

    constructor() {
        this.options = {
            behavior: window.matchMedia('(prefers-reduced-motion: reduce)')?.matches ? 'auto' : 'smooth',
        }
    }

    @HostListener('click')
    public onClick(): void {
        const element = this.element instanceof ElementRef ? this.element.nativeElement : this.element
        element?.scrollIntoView(this.options)
    }

}
