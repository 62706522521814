import { enUS } from 'date-fns/locale'

import { AppConfigInterface } from '@app-config/app/app-config.interface'

// noinspection JSUnusedGlobalSymbols
export const AppConfig: AppConfigInterface = {

    environment: 'staging',

    locales: [
        enUS,
    ],

    api: {
        graphQLEndpoint: 'https://api.predatortour.kubernetes.pwstaging.tech/graphql',
        wsGraphQLEndpoint: 'wss://api.predatortour.kubernetes.pwstaging.tech/graphql',
    },

    cache: {
        cacheByDefault: true,
        ttl: 60 * 60,
    },

    bugsnag: {
        enabled: true,
        apiKey: '15aff6f09a34b3bf55aead43007807ab',
    },

}
