import { Injectable } from '@angular/core'
import { CanDeactivate } from '@angular/router'
import { AlertController } from '@ionic/angular'

export interface UnsavedChanges {
    hasUnsavedChanges(): boolean | Promise<boolean>

    cleanupUnsavedChanges?(): void | Promise<void>
}

@Injectable({
    providedIn: 'root',
})
export class UnsavedChangesGuard implements CanDeactivate<UnsavedChanges> {

    constructor(
        private readonly alertController: AlertController,
    ) {
    }

    public async canDeactivate(component: UnsavedChanges): Promise<boolean> {
        let hasUnsavedChanges = component.hasUnsavedChanges()
        if (hasUnsavedChanges instanceof Promise) {
            hasUnsavedChanges = await hasUnsavedChanges
        }

        if (! hasUnsavedChanges) {
            return true
        }

        return new Promise<boolean>((resolve) => {
            this.alertController.create({
                message: 'You have unsaved changes. Are you sure you want to leave?',
                buttons: [
                    {
                        text: 'Keep editing',
                        role: 'cancel',
                        handler: () => resolve(false),
                    },
                    {
                        text: 'Discard changes',
                        role: 'destructive',
                        handler: async () => {
                            if (component.cleanupUnsavedChanges) {
                                await component.cleanupUnsavedChanges()
                            }
                            resolve(true)
                        },
                    },
                ],
            }).then((alert) => alert.present())
        })
    }
}
